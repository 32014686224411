import { EXTENSION_TO_ICON, ID_PREFIXES } from 'utils/constants';
import transform from 'lodash/transform';
import isPlainObject from 'lodash/isPlainObject';
import isFunction from 'lodash/isFunction';
import last from 'lodash/last';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { stringify } from 'query-string';
import omit from 'lodash/omit';
import { getType, walk } from 'mobx-state-tree';
import findKey from 'lodash/findKey';
import isMatch from 'lodash/isMatch';
import isObject from 'lodash/isObject';
import isEqual from 'lodash/isEqual';
import flattenDeep from 'lodash/flattenDeep';
import set from 'lodash/set';

const { isArray } = Array;

// exporting const throws webpack 4 TypeError: “Object(…) is not a function”
export function underscoredToCamel(str) {
  return str && str.replace(/_([a-z])/g, g => g[1].toUpperCase());
}

// taken https://stackoverflow.com/questions/30521224/javascript-convert-pascalcase-to-underscore-case
export const camelcaseToUnderscore = str =>
  str
    .replace(/\.?([A-Z]+)/g, function (x, y) {
      return '_' + y.toLowerCase();
    })
    .replace(/^_/, '');

const modifyKeysDeep = (obj, modification, skip, abortKeys) => {
  if (isArray(obj)) {
    return obj.map(item => modifyKeysDeep(item, modification, skip, abortKeys));
  }
  if (isPlainObject(obj)) {
    return transform(
      obj,
      (result, value, key) => {
        let resultValue = value;

        if (isArray(value)) {
          resultValue = value.map(v =>
            modifyKeysDeep(v, modification, skip, abortKeys),
          );
        }

        if (isPlainObject(value) && abortKeys.indexOf(key) < 0) {
          resultValue = modifyKeysDeep(value, modification, skip, abortKeys);
        } else if (isPlainObject(value)) {
          resultValue = value;
        }

        if (skip.indexOf(key) >= 0) {
          result[key] = resultValue;
        } else {
          result[modification(key)] = resultValue;
        }
      },
      {},
    );
  }

  return obj;
};

export const serialize = (
  iteratee,
  fn = underscoredToCamel,
  skipKeys = [],
  abortKeys = [],
) => modifyKeysDeep(iteratee, fn, skipKeys, abortKeys);

export const deserialize = (
  iteratee,
  fn = camelcaseToUnderscore,
  skipKeys = [],
  abortKeys = [],
) => modifyKeysDeep(iteratee, fn, skipKeys, abortKeys);

export const addToPosition = (list, dataToAdd, position = 0) => {
  const fixedPosition = position < 0 ? 0 : position;
  list.splice(fixedPosition, 0, dataToAdd);
  return list[fixedPosition];
};

export const getPropertyOrResult = (obj, propName, ...params) =>
  isFunction(obj[propName]) ? obj[propName](...params) : obj[propName];

export function interpolateString(template, replacements) {
  return Object.keys(replacements).reduce((result, key) => {
    return result.replace(`%${key}`, replacements[key]);
  }, template);
}

// returns root element if child is a match
export function deepFind(collection, key, fn) {
  return collection.find(item => {
    if (fn(item)) return true;
    if (!item || !isFunction(item[key] && item[key].find)) return false;
    return deepFind(item[key], key, fn);
  });
}

export function findFirstSuch(collection, key, fn, from = 0) {
  for (let i = from; i < collection.length; i++) {
    const item = collection[i];

    if (fn(item)) {
      return item;
    }

    if (item[key]) {
      const result = findFirstSuch(item[key], key, fn);
      if (result) {
        return result;
      }
    }
  }
}

// ToDo: review and refactor
export function findAllSuch(collection, key, fn, from = 0) {
  let found = [];
  for (let i = from; i < collection.length; i++) {
    const item = collection[i];

    if (fn(item)) {
      found.push(item);
    }

    if (item[key]) {
      const result = findAllSuch(item[key], key, fn);
      if (result) {
        found = [...found, ...result];
      }
    }
  }
  return found;
}

export const filterTree = (tree, query) => {
  return tree.reduce((acc, folder) => {
    const folderToRender = { ...folder };
    const { sessions, folders } = folderToRender;
    if (!folders && !sessions) {
      return acc;
    }

    if (!isEmpty(folders)) {
      folderToRender.folders = filterTree(folders, query);
    }

    if (!isEmpty(sessions)) {
      folderToRender.sessions = sessions.filter(session => {
        const matchName = session.name.toLowerCase().match(query);
        const matchChildName =
          session.childSessions &&
          session.childSessions
            .map(child => child.name)
            .join(' ')
            .toLowerCase()
            .match(query);

        return matchName || matchChildName;
      });
    }

    if (!isEmpty(folderToRender.sessions) || !isEmpty(folderToRender.folders)) {
      acc.push(folderToRender);
    }
    return acc;
  }, []);
};

export function generateNameInSequence(name) {
  const oldId = last(name.match(/(\d+)/g)) || 0;
  const newId = Number(oldId) + 1;

  if (oldId) {
    return (
      name.substr(0, name.lastIndexOf(oldId)) +
      newId +
      name.substr(name.lastIndexOf(oldId) + oldId.length)
    );
  } else {
    return `${name} ${newId}`;
  }
}

export const generateTmpId = (prefix = ID_PREFIXES.new) => {
  return prefix + new Date().getTime();
};

export function getNumberFromKeyEvent({ keyCode }) {
  if (keyCode >= 96 && keyCode <= 105) {
    return keyCode - 96;
  } else if (keyCode >= 48 && keyCode <= 57) {
    return keyCode - 48;
  }
  return null;
}

export function isSpecialKey({ keyCode }) {
  return [
    8 /*backspace*/, 13 /*enter*/, 40, 39, 38, 37 /*arrows*/, 45 /*insert*/,
    46 /*delete*/,
  ].includes(keyCode);
}

/**
 *
 * @param queryParams {Object}
 * @param tab {String}
 * @returns {Object}
 */
export const stringifyTab = (queryParams, tab) => {
  return stringify(tab ? { ...queryParams, tab } : omit(queryParams, ['tab']), {
    arrayFormat: 'bracket',
  });
};

/**
 * Walks the mobx state tree and finds the first
 * node of specific type with the specified id.
 * @param type
 * @param target
 * @param id
 *
 * @returns {import('stores/Block').BlockNode | null}
 */
export const findNodeById = (type, target, id) => {
  let result = null;
  walk(target, node => {
    if (!result && node.id === id && getType(node) === type) result = node;
  });
  return result;
};

/**
 * Returns object with non null values
 * @param obj {Object}
 * @returns {Object}
 */
export const compactObject = obj =>
  transform(
    obj,
    (result, value, key) => {
      if (isPlainObject(value)) {
        result[key] = compactObject(value);
        return result;
      }
      if (!isNil(value)) {
        result[key] = value;
      }
      return result;
    },
    {},
  );

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export const difference = (object, base) => {
  return transform(object, (result, value, key) => {
    if (!isEqual(value, base[key])) {
      result[key] =
        isObject(value) && isObject(base[key])
          ? difference(value, base[key])
          : value;
    }
  });
};

// categories helpers
export const createArrayFromCategories = (categories = {}) => {
  return Object.keys(categories)
    .filter(key => key !== 'nextId' && !isNaN(parseInt(key, 10)))
    .map(key => ({ ...categories[key], key: parseInt(key, 10) }));
};

export const findCategoryKey = (category, categories) => {
  if (category && category.color && category.name && categories) {
    const key = findKey(categories, cat => isMatch(cat, category));
    return key && parseInt(key, 10);
  }
};

export const addCategory = (category, categories) => {
  let id = null;
  let updatedCategories = null;

  if (category && category.color && categories) {
    id = findCategoryKey(category, categories);

    if (!id) {
      // fallback to fix current bug in production
      // check max between nextId and latest category key
      const lastCat = last(createArrayFromCategories(categories));
      const lastCategoryKey = lastCat ? lastCat.key : 0;
      id = Math.max(categories.nextId, lastCategoryKey + 1);

      // at some cases nextId will be +2 to last category.key,
      // which is fine
      updatedCategories = {
        ...categories,
        [id]: { ...category },
        nextId: id + 1,
      };
    }
  }
  return { id, updatedCategories };
};

export const filterByQuery = (array = [], query = '') =>
  array.filter(entry => searchInObjectAttrs(entry, query));

export const searchInObjectAttrs = (
  obj,
  query,
  attrsToLook = ['name', 'description', 'authorName'],
) =>
  Boolean(
    attrsToLook.filter(
      key => obj[key] && new RegExp(query + '*', 'gi').test(obj[key]),
    ).length,
  );

// Different from lodash.isEmpty because that lodash.isEmpty(1) === true
export const isEmptyValue = value => {
  return (
    isNil(value) ||
    ((Array.isArray(value) || typeof value == 'string') && !value.length)
  );
};

export const createFormData = (file, model, type) => {
  const formData = new FormData();
  const fileName = file.name;
  formData.append(`${model}[${type}]`, file, fileName);
  return formData;
};

export const removeFileParams = type => {
  const params = {};
  params['remove_' + type] = true;
  return params;
};

/**
 * Get an array with all the errors from backend
 * Usually a generic message and then a more detailed message
 * @param {Error} error
 * @returns string[] | null
 */
export const getMessageFromError = error => {
  if (error.response?.data?.error) {
    const errorMessages = [error.response.data.error];
    if (error.response.data.errors) {
      errorMessages.push(
        ...flattenDeep(Object.values(error.response.data.errors)),
      );
    }
    return errorMessages;
  }

  return null;
};

export const getRGBPartsFromHex = hexCode => {
  let hex = hexCode.replace('#', '');
  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `${r},${g},${b}`;
};

/**
 * Convert hex to rgba
 * Taken from https://gist.github.com/danieliser/b4b24c9f772066bcf0a6
 *
 * @param {string} hexCode
 * @param {number} opacity
 * @returns {string}
 */
export const convertHexToRGBA = (hexCode, opacity = 1) => {
  /* Backward compatibility for whole number based opacity values. */
  if (opacity > 1 && opacity <= 100) {
    opacity = opacity / 100;
  }

  return `rgba(${getRGBPartsFromHex(hexCode)},${opacity})`;
};
export const preventNonDigitsOnNumberInput = e => {
  if ([' ', '-', '.', ',', 'e'].indexOf(e.key) >= 0) {
    e.preventDefault();
  }
};
/**
 * Simply call up a function if the event handler is called on an invalid form control
 *
 * For example on blur, if an input is invalid, clear it's value
 * @param {(e: React.FocusEvent<HTMLInputElement>) => void} cb
 * @return {(e: React.FocusEvent<HTMLInputElement>) => void} e
 */
export const getInvalidCb = cb => e => {
  if (!e.target.checkValidity()) {
    cb(e);
  }
};

/**
 * Get fa icon name for a file's extension.
 *
 * @param {string} name
 * @returns {string}
 */
export const getFileIcon = name => {
  const nameSplitList = name.split('.');
  return (
    (nameSplitList.length &&
      EXTENSION_TO_ICON[nameSplitList[nameSplitList.length - 1]]) ||
    'file'
  );
};

/**
 * Get path difference to understand what needs to be changed
 * Returns result in a way that can be used with lodash set
 *
 * @param {*} patch
 * @param {*} irrelevantPath
 * @returns {string}
 */
export const getDiffPathForPatch = (patch, irrelevantPath) => {
  return patch.path.replace(`${irrelevantPath}/`, '').replaceAll('/', '.');
};

/**
 * Get the cummulative changes from patches
 * Only takes into account replaces which is what's used for text changes
 *
 * @param {import('mobx-state-tree').IJsonPatch[]} patch
 * @param {string} irrelevantPath
 * @returns {Record<string, any>}
 */
export const getUpdateFromPatches = (patch, irrelevantPath) => {
  return patch.reduce((acc, curr) => {
    const path = getDiffPathForPatch(curr, irrelevantPath);
    if (curr.op !== 'replace') {
      return acc;
    }

    return {
      ...acc,
      ...set({}, path, curr.value),
    };
  }, {});
};

/*
 * Calculate human readable value of the zoom
 * @param {number} zoom
 * @returns {string}
 */
export const getZoomString = zoom => {
  return `${zoom * 100}%`;
};

/**
 * Walks the mobx state tree and gets all ids of child of specific type
 * node of specific type with the specified id.
 * Implies wanted node has id field
 * @param {any} type
 * @param {any} target
 *
 * @returns {string[]}
 */
export const getAllChildIdsOfType = (type, target) => {
  let ids = [];
  walk(target, node => {
    if (getType(node) === type) {
      ids.push(node.id);
    }
  });
  return ids;
};

import DOMPurify, { Config } from 'isomorphic-dompurify';

const defaultConfig = {
  ALLOWED_TAGS: [
    'p',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'span',
    'div',
    'strong',
    'b',
    'em',
    'u',
    's',
    'strike',
    'i',
    'sub',
    'sup',
    'br',
    'ul',
    'li',
    'ol',
    'a',
    'img',
  ],
  ADD_ATTR: ['contenteditable'],
};

DOMPurify.setConfig(defaultConfig);

// Allow 'contenteditable' attribute but only when it's value is false.
DOMPurify.addHook('uponSanitizeAttribute', function (node, data) {
  if (data.attrName === 'contenteditable' && data.attrValue !== 'false') {
    data.keepAttr = false;
  }
});

export default {
  sanitize: (
    dirty: string | Node,
    config?: Config,
  ): string | DocumentFragment | HTMLElement => {
    if (config) {
      DOMPurify.clearConfig();
      const result = DOMPurify.sanitize(dirty, { ...defaultConfig, ...config });
      DOMPurify.setConfig(defaultConfig);
      return result;
    }
    return DOMPurify.sanitize(dirty);
  },
};

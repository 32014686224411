// IE11 does not want to fire onload function on just opened window

import { APP_HOST } from 'utils/constants';
import { isDomAvailable } from './ssr';
export function openNewWindow(
  promise: Promise<any>,
  message: string,
  url = '/sessions/new',
) {
  const win = window.open(url);

  if (!win) return;

  const winPromise = new Promise((resolve, reject) => {
    try {
      win.addEventListener('message', e => {
        if (e.origin !== APP_HOST) {
          return;
        }
        if (e.data.ready) {
          resolve(null);
          win.removeEventListener('message', resolve);
        }
      });
    } catch (e) {
      reject(e);
    }
  });

  return Promise.all([winPromise, promise]).then(
    results => {
      win.postMessage({ sessionId: results[1].id, message: message }, APP_HOST);
      return results[1];
    },
    error => {
      win.close();
      throw error;
    },
  );
}

export function openShareWindow(
  width: number,
  height: number,
  loadingText = 'Loading...',
) {
  const win = window.open(
    '',
    'share' + Date.now(),
    `scrollbars=yes,resizable=yes,toolbar=no,location=yes,width=${width},height=${height}`,
  );

  if (!win) return;

  win.document.write(
    `<span style="font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif">${loadingText}</span>`,
  );
  return win;
}

export const maxDate = new Date(8640000000000000);

export function immutableSplice(
  array: any[],
  start: number,
  deleteCount: number,
  ...items: any[]
) {
  return [
    ...array.slice(0, start),
    ...items,
    ...array.slice(start + deleteCount),
  ];
}

/**
 *
 * @returns {boolean}
 */
export const isMac = () => {
  return navigator.userAgent.indexOf('Macintosh') > -1;
};

export const isInsideIframe = () => {
  if (!isDomAvailable) return false;

  return window !== window.top;
};

/**
 * Gives you a safe localStorage object that will not throw an error if localStorage is not available, but
 * will just silently ignore all calls.
 *
 * @returns {localStorage}
 */
export const safeLocalStorage = () => {
  const localStorageMock = {
    getItem: () => null,
    setItem: () => {},
    removeItem: () => {},
    clear: () => {},
    key: () => null,
    length: 0,
  };

  if (!isDomAvailable) return localStorageMock;

  const isLocalStorageAvailable = () => {
    try {
      localStorage.setItem('testKey', '1');
      localStorage.removeItem('testKey');
      return true;
    } catch {
      return false;
    }
  };

  return isInsideIframe() || !isLocalStorageAvailable()
    ? localStorageMock
    : localStorage;
};

/**
 * Record a date in the local storage
 * Useful to recording different values to see if alerts should be displayed
 * based on last dimissal
 */
export const recordDateInLocalStorage = (dateKey: string): string => {
  const nowString = new Date().toISOString();
  localStorage.setItem(dateKey, nowString);
  return nowString;
};

export const setArrayInSearchParams = (
  searchParams: URLSearchParams,
  key: string,
  values: string[] | string,
): void => {
  let valueList = values;
  if (!Array.isArray(values)) {
    valueList = [values];
  }

  let formattedKey = key;
  if (!formattedKey.endsWith('[]')) {
    formattedKey = `${formattedKey}[]`;
  }
  searchParams.delete(formattedKey);
  (valueList as string[]).forEach(val =>
    searchParams.append(formattedKey, val),
  );
};

export const removeValuesFromArrayInSearchParams = (
  searchParams: URLSearchParams,
  key: string,
  values: string[] | string,
): void => {
  let valueList = values;
  if (!Array.isArray(values)) {
    valueList = [values];
  }

  const formattedKey = key.endsWith('[]') ? key : `${key}[]`;
  const currentValues = searchParams.getAll(formattedKey);
  const newValues = currentValues.filter(val => !valueList.includes(val));
  searchParams.delete(formattedKey);
  newValues.forEach(val => searchParams.append(formattedKey, val));
};

// Our users are not tech savyy this is fine for our use cases
// For now we don't care if they fake it because we're only limiting zoom
export const BROWSER_NAME = (() => {
  // SSR CHECK
  if (!global.navigator || !global.navigator.userAgent) {
    return 'Unknown Browser';
  }
  const userAgent = navigator.userAgent.toLowerCase();

  // Check for common browsers
  if (userAgent.includes('firefox')) {
    return 'Firefox';
  } else if (userAgent.includes('edg')) {
    return 'Edge';
  } else if (userAgent.includes('chrome') && !userAgent.includes('edg')) {
    return 'Chrome';
  } else if (userAgent.includes('safari') && !userAgent.includes('chrome')) {
    return 'Safari';
  } else if (userAgent.includes('opera') || userAgent.includes('opr')) {
    return 'Opera';
  }

  return 'Unknown Browser';
})();
